import * as React from "react";
import { Routes, Route, useParams, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDialogs } from "@toolpad/core/useDialogs";
import SecurityIcon from "@mui/icons-material/Security";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AppContext from "../../utils/context";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import moment from "moment";

export default function View() {
  const dialogs = useDialogs();
  const appContext = React.useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({ items: [] });
  const [logs, setLogs] = React.useState([]);
  const [searchParams] = useSearchParams();
  const [currentLog, setCurrentLog] = React.useState(null);

  // let { projectId, instanceId } = useParams();

  React.useEffect(() => {
    loadLogs();
  }, [searchParams]);

  const loadLogs = async () => {
    // const { data, error } = await appContext.database.from("instances").select('*,projects(name)').eq('project_id', projectId);
    const q = appContext.database
      .rpc("get_logs", {})
      .select(
        "id,user_id,workspace_id,workspace_name,project_id,project_name,instance_id,instance_name,service_id,service_name,created_at"
      );
    if (searchParams.get("instance_id")) {
      q.eq("instance_id", searchParams.get("instance_id"));
    }
    if (searchParams.get("service_id")) {
      q.eq("service_id", searchParams.get("service_id"));
    }
    if (searchParams.get("project_id")) {
      q.eq("project_id", searchParams.get("project_id"));
    }
    if (searchParams.get("workspace_id")) {
      q.eq("workspace_id", searchParams.get("workspace_id"));
    }

    const { data, error } = await q;
    setLogs(data);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentLog(null);
  };

  const showDetail = async (row) => {
    const { data, error } = await appContext.database
      .rpc("get_logs")
      .eq("id", row.id)
      .single();
    setCurrentLog(data);
    setOpen(true);
  };

  const columns = [
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{moment(params.row.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
        );
      },
    },
    {
      field: "user_id",
      headerName: "User",
      flex: 1,
    },
    {
      field: "workspace_id",
      headerName: "Workspace",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.workspace_name}
            <br />
            <small>{params.row.workspace_id}</small>
          </>
        );
      },
    },
    {
      field: "project_id",
      headerName: "Project",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.project_name}
            <br />
            <small>{params.row.project_id}</small>
          </>
        );
      },
    },
    {
      field: "instance_id",
      headerName: "Instance",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.instance_name}
            <br />
            <small>{params.row.instance_id}</small>
          </>
        );
      },
    },
    {
      field: "service_id",
      headerName: "Service",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.service_name}
            <br />
            <small>{params.row.service_id}</small>
          </>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MoreHorizIcon />}
          label="Details"
          onClick={() => {
            showDetail(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Loge details</DialogTitle>

        <DialogContent sx={{ width: "500px" }}>
          {currentLog && (
            <List sx={{ width: "100%" }}>
              <ListItem>
                <ListItemText
                  primary="Date"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.primary", display: "inline" }}
                      >
                        <>
                          {moment(currentLog.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </>
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="User"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.primary", display: "inline" }}
                      >
                        {currentLog.user_id}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Workspace"
                  secondary={
                    <React.Fragment>
                      {currentLog.workspace_name && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: "text.primary", display: "block" }}
                        >
                          {currentLog.workspace_name}
                        </Typography>
                      )}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.secondary", display: "block" }}
                      >
                        {currentLog.workspace_id
                          ? currentLog.workspace_id
                          : "N/A"}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Project"
                  secondary={
                    <React.Fragment>
                      {currentLog.project_name && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: "text.primary", display: "block" }}
                        >
                          {currentLog.project_name}
                        </Typography>
                      )}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.secondary", display: "block" }}
                      >
                        {currentLog.project_id ? currentLog.project_id : "N/A"}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Instance"
                  secondary={
                    <React.Fragment>
                      {currentLog.instance_name && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: "text.primary", display: "block" }}
                        >
                          {currentLog.instance_name}
                        </Typography>
                      )}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.secondary", display: "block" }}
                      >
                        {currentLog.instance_id
                          ? currentLog.instance_id
                          : "N/A"}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Service"
                  secondary={
                    <React.Fragment>
                      {currentLog.service_name && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: "text.primary", display: "block" }}
                        >
                          {currentLog.service_name}
                        </Typography>
                      )}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.secondary", display: "block" }}
                      >
                        {currentLog.service_id ? currentLog.service_id : "N/A"}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Message"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.primary", display: "inline" }}
                      >
                        {currentLog.message}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Data</Typography>
                  <pre style={{ overflow: "auto" }}>
                    <code>
                      {JSON.stringify(
                        currentLog.data,
                        null,
                        " "
                      )}
                    </code>
                  </pre>
                </ListItemText>
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <div className="wrapper" style={{ display: "flex", padding: "20px" }}>
        <Paper
          style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ padding: "10px", display: "flex", flexDirection: "row" }}>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <h3 style={{ padding: 0, margin: 0 }}>Logs</h3>
            </Box>
          </Box>
          {logs && (
            <DataGrid
              rows={logs}
              columns={columns}
              getRowHeight={() => "auto"}
              initialState={{
                pagination: {
                  paginationModel: {
                    pservicesSize: 50,
                  },
                },
              }}
              disableRowSelectionOnClick={true}
              disableColumnResize={true}
              filterModel={filters}
              onFilterModelChange={(model) => setFilters(model)}
              style={{ border: "none", flexGrow: 1 }}
            />
          )}
        </Paper>
      </div>
    </div>
  );
}
