import * as React from "react";
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import { schema, uischema } from "./schema";
import AppContext from "../../utils/context";

export default function ProjectDialog(props) {
  const { events, database } = React.useContext(AppContext);
  const [formData, setFormData] = React.useState({});
  const [formErrors, setFormErrors] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [callback, setCallback] = React.useState(null);
  const [isDrawer, setDrawer] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(null);
  const [hasTriedToSave, setHasTriedToSave] = React.useState(false);

  React.useEffect(() => {
    const offCreate = events.on("create-project", handleCreate);
    const offEdit = events.on("edit-project", handleEdit);

    // Cleanup on unmount
    return () => {
      offCreate();
      offEdit();
    };
  }, [events]);

  const handleClose = () => {
    // Réinitialisation des états lors de la fermeture du formulaire
    setOpen(false);
    setFormData({});
    setTemplates([]);
    setCallback(null);
    setWorkspace(null);
    setDrawer(false);
    setHasTriedToSave(false);
    setFormErrors(null); // Réinitialisation des erreurs
  };

  const handleCreate = async (e) => {
    try {
      const { data: templatesData, error } = await database
        .from("project_templates_workspaces")
        .select("project_templates(id, name, description)")
        .eq("workspace_id", e.data.id);

      if (error) throw error;

      setWorkspace(e.data);
      setTemplates(templatesData.map(({ project_templates }) => project_templates));
      setCallback(() => e.callback);
      setDrawer(true);
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error loading templates: ${error.message}`, severity: "error" },
      });
    }
  };

  const handleEdit = (e) => {
    setFormData(e.data);
    setCallback(() => e.callback);
    setOpen(true);
  };

  const save = async () => {
    setHasTriedToSave(true); // L'utilisateur tente de sauvegarder

    if (formErrors && formErrors.length) {
      events.dispatch("alert-message", {
        data: { message: "Please correct the errors", severity: "error" },
      });
      return;
    }

    try {
      const { id, name, description, workspace_id, project_template_id } = formData;
      const query = id
        ? database.from("projects").update({ name, description }).eq("id", id).select()
        : database.from("projects").insert([{ name, description, workspace_id, project_template_id }]).select();

      const { data, error } = await query;
      const message = id ? "updated" : "created";

      events.dispatch("alert-message", {
        data: { message: `Project ${error ? "not " : ""}${message}`, severity: error ? "error" : "success" },
      });

      if (callback) callback(!error ? data[0] : false);
      if (!error) handleClose(); // Fermer le formulaire seulement si la sauvegarde réussit
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error saving project: ${error.message}`, severity: "error" },
      });
    }
  };

  const addProject = (template) => {
    handleEdit({
      data: { id: null, name: "", description: "", workspace_id: workspace.id, project_template_id: template.id },
      callback,
    });
  };

  const displayTemplateDescription = () => {
    const template = templates.find((template) => template.id === formData.template);
    return template?.description || "";
  };

  const setFormResult = ({ data, errors }) => {
    setFormData(data);
    setFormErrors(errors);
  };

  return (
    <React.Fragment>
      <Drawer open={isDrawer} anchor="right" onClose={() => setDrawer(false)} sx={{ overflow: "none" }}>
        <Box sx={{ minWidth: "34vw", flexGrow: 1, display: "flex", flexDirection: "column", padding: "20px" }}>
          <DataGrid
            getRowHeight={() => "auto"}
            rows={templates}
            columns={[
              {
                field: "name",
                headerName: "Templates",
                flex: 1,
                renderCell: (params) => (
                  <div style={{ padding: "15px 0", lineHeight: "normal" }}>
                    {params.row.name}
                    <br />
                    <small>{params.row.description}</small>
                  </div>
                ),
              },
              {
                field: "actions",
                type: "actions",
                getActions: (params) => [
                  <Button onClick={() => addProject(params.row)}>Add</Button>,
                ],
              },
            ]}
            initialState={{ pagination: { paginationModel: { pservicesSize: 50 } } }}
            disableRowSelectionOnClick
            disableColumnResize
            style={{ border: "none", flexGrow: 1 }}
          />
        </Box>
      </Drawer>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Project</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "500px", paddingTop: "20px" }}>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={formData}
              renderers={materialRenderers}
              cells={materialCells}
              onChange={setFormResult}
              validationMode={hasTriedToSave ? "ValidateAndShow" : "ValidateAndHide"}
              config={{ showUnfocusedDescription: true }}
            />
            {displayTemplateDescription()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save} autoFocus>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
