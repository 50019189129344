import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AppContext from "../../utils/context";

export default function ProjectDialog(props) {
  const appContext = React.useContext(AppContext);
  const { events, database } = appContext;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [workspaceScope, setWorkspaceScope] = React.useState(null);
  const [callbacks, setCallbacks] = React.useState({});
  const [componentScope, setComponentScope] = React.useState(null);

  React.useEffect(() => {
    const off = events.on("search", (e) => {
      if(!open)
        handleSearch(e);
    });

    return () => {
      off();
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCallbacks({});
    setWorkspaceScope(null);
    setComponentScope(null);
    setItems([]);
    setSearch("");
  };

  const handleSearch = async (e) => {
    setCallbacks(() => e.callbacks);
    setOpen(true);
    if (e?.workspaceScope) setWorkspaceScope(e.workspaceScope);
    if (e?.componentScope) setComponentScope(e.componentScope);
  };

  React.useEffect(() => {
    const searchItems = async () => {
      try {
        const rWorkspaces = callbacks?.workspace ? await searchWorkspace(search) : [];
        const rProjects = callbacks?.project ? await searchProject(search) : [];
        const rInstances = callbacks?.instance ? await searchInstance(search) : [];

        setItems([...rWorkspaces, ...rProjects, ...rInstances].sort((a, b) => a.name.localeCompare(b.name)));
      } catch (error) {
        events.dispatch("alert-message", {
          data: { message: `Error fetching search results: ${error.message}`, severity: "error" },
        });
      }
    };

    if (search.length >= 2) {
      searchItems();
    } else {
      setItems([]);
    }
  }, [search]);

  const searchWorkspace = async (search) => {
    if (workspaceScope) return [];

    try {
      const { data, error } = await database
        .from("workspaces")
        .select("id, name")
        .ilike("name", `%${search}%`);

      if (error) throw error;

      return data.map((item) => ({ ...item, type: "Workspace" }));
    } catch (error) {
      console.error("Error fetching workspaces:", error);
      events.dispatch("alert-message", {
        data: { message: `Error fetching workspaces: ${error.message}`, severity: "error" },
      });
      return [];
    }
  };

  const searchProject = async () => {
    try {
      let query = database.from("projects").select(
        `id, name, description, workspaces!inner(id, name)`
      );
      
      if (workspaceScope) query = query.eq("workspace_id", workspaceScope);

      query = query.ilike("name", `%${search}%`);
      const { data, error } = await query;

      if (error) throw error;

      return data.map((item) => ({ ...item, type: "Project" }));
    } catch (error) {
      console.error("Error fetching projects:", error);
      events.dispatch("alert-message", {
        data: { message: `Error fetching projects: ${error.message}`, severity: "error" },
      });
      return [];
    }
  };

  const searchInstance = async () => {
    try {
      let query = database.from("instances").select(
        `id, name, projects!inner(id, name, workspace_id, workspaces!inner(id, name)),components!inner(name)`
      );

      if (workspaceScope) query = query.eq("projects.workspaces.id", workspaceScope);
      if (componentScope) query = query.in("components.name", componentScope);

      query = query.ilike("name", `%${search}%`);
      const { data, error } = await query;

      if (error) throw error;

      return data.map((item) => ({ ...item, type: "Instance" }));
    } catch (error) {
      console.error("Error fetching instances:", error);
      events.dispatch("alert-message", {
        data: { message: `Error fetching instances: ${error.message}`, severity: "error" },
      });
      return [];
    }
  };

  const selectItem = (item) => {
    const clbk = callbacks[item.type.toString().toLowerCase()];
    if (clbk) {
      console.log(clbk)
      clbk(item);
      setOpen(false);
      handleClose()
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 440,
      renderCell: (params) => (
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1em", paddingBottom: "1em" }}>
          <div>
            <strong>{params.row.name}</strong>
            <br />
            <small>{params.row.type} ID {params.row.id}</small>
          </div>
          <small>
            {params.row.type === "Project" && (
              <>
                {params.row.description}
                <br />
                Workspace: {params.row.workspaces.name}
              </>
            )}
            {params.row.type === "Instance" && (
              <>
                {params?.row.components?.name && <>Composant: {params.row.components.name}</>}
                <br />
                {params?.row.projects?.name && <>Project: {params.row.projects.name}</>}
                <br />
                {params?.row.projects?.workspaces && <>Workspace: {params.row.projects.workspaces.name}</>}
              </>
            )}
          </small>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<KeyboardArrowRightIcon />}
          label="View"
          onClick={() => selectItem(params.row)}
        />,
      ],
    },
  ];

  return (
    <React.Fragment>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ paddingTop: "1em" }}>
          <TextField
            placeholder="Search"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            value={search}
            variant="standard"
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "500px" }}>
            {items.length > 0 && (
              <Box sx={{ height: "500px", display: "flex", flexDirection: "column" }}>
                <DataGrid
                  rows={items}
                  columns={columns}
                  getRowHeight={() => "auto"}
                  disableColumnResize={true}
                  style={{ border: "none", flexGrow: 1 }}
                  disableColumnMenu
                  columnHeaderHeight={0}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50,
                      },
                    },
                  }}
                  pageSizeOptions={[50]}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
