import * as React from "react";
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Drawer } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import AppContext from "../../utils/context";
import { InstancePickerTester, InstancePicker } from "./instancePicker";

export default function FormDialog(props) {
  const appContext = React.useContext(AppContext);
  const { events, database } = appContext;
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [formErrors, setFormErrors] = React.useState(null);
  const [instance, setInstance] = React.useState(null);
  const [isDrawer, setDrawer] = React.useState(false);
  const [project, setProject] = React.useState(null);
  const [components, setComponents] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [hasTriedToSave, setHasTriedToSave] = React.useState(false);
  const [schema, setSchema] = React.useState({});
  const [uischema, setUischema] = React.useState({});
  const [callback, setCallback] = React.useState(null);

  React.useEffect(() => {
    const offCreate = events.on("create-instance", handleNew);
    const offEdit = events.on("edit-instance", handleEdit);

    return () => {
      offCreate();
      offEdit();
    };
  }, []);

  const handleNew = async (e) => {
    try {
      if(e?.callback) setCallback(() => e.callback);
      const { data: componentsData, error } = await database
        .from("components_project_templates")
        .select(`
          components(
            id,
            name,
            description,
            jsonschema,
            jsonschema_form
          )
        `)
        .eq("project_template_id", e.project.project_template_id);

      if (error) throw error;

      setProject(e.project);
      setComponents(componentsData.map((component) => component.components));
      setDrawer(true);
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error loading components: ${error.message}`, severity: "error" },
      });
    }
  };

  const handleEdit = async (e) => {
    try {
      let data;
      if (e?.project) setProject(e.project);
      if(e?.callback) setCallback(() => e.callback);

      if (e.instance.id == null) {
        const { data: recordData, error } = await database
          .from("components")
          .select(`
            id,
            name,
            description,
            jsonschema,
            jsonschema_form
          `)
          .eq("id", e.instance.components.id)
          .single();

        if (error) throw error;

        data = {
          id: null,
          name: null,
          components: recordData,
          metadata: {},
        };
      } else {
        const { data: recordData, error } = await database
          .from("instances")
          .select(`
            id,
            name,
            metadata,
            components(
              id,
              name,
              description,
              jsonschema,
              jsonschema_form
            )
          `)
          .eq("id", e.instance.id)
          .single();

        if (error) throw error;

        data = recordData;
      }

      setInstance(data);
      setupFormSchemas(data);
      setTitle(e.instance.id != null ? e.instance.name : `New instance of ${e.instance.components.name}`);
      setFormData({ ...data.metadata, name: data.name });
      setOpen(true);
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error loading instance: ${error.message}`, severity: "error" },
      });
    }
  };

  const setupFormSchemas = (data) => {
    let s = { ...data.components.jsonschema };
    s.properties.name = {
      type: "string",
      minLength: 3,
      description: "Please enter a component name",
    };
    if (!s?.required) s.required = [];
    s.required.push("name");

    let sui = { ...data.components.jsonschema_form };
    sui.elements.unshift({
      type: "Control",
      scope: "#/properties/name",
    });

    setSchema(s);
    setUischema(sui);
  };

  const save = async () => {
    setHasTriedToSave(true);

    if (formErrors && formErrors.length) {
      events.dispatch("alert-message", {
        data: { message: "Please correct the errors", severity: "error" },
      });
      return;
    }

    try {
      if (instance.id == null) {
        const { data, error } = await database
          .from("instances")
          .insert({
            name: formData.name,
            component_id: instance.components.id,
            project_id: project.id,
            metadata: formData,
          }).select();
          
        if (error) throw error;
        if(callback) callback(data);
      } else {
        const { name, ...metadataWithoutName } = formData;
        const { data, error } = await database
          .from("instances")
          .update({
            name: formData.name,
            component_id: instance.components.id,
            project_id: project.id,
            metadata: metadataWithoutName,
          })
          .eq("id", instance.id)
          .select();

        if (error) throw error;
        if(callback) callback(data);
      }

      handleClose();
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error saving instance: ${error.message}`, severity: "error" },
      });
    }
  };

  const setFormResult = ({ data, errors }) => {
    setFormData(data);
    setFormErrors(errors);
  };

  const handleClose = () => {
    setOpen(false);
    setDrawer(false);
    setFormData({});
    setFormErrors(null);
    setSchema({});
    setUischema({});
    setTitle("");
    setComponents([]);
    setProject(null);
    setInstance(null);
    setHasTriedToSave(false);
    setCallback(null);
  };

  const addInstance = (component) => {
    handleEdit({
      instance: { id: null, components: component },
      project,
    });
  };

  // const customRenderers = [
  //   ...materialRenderers,
  //   {
  //     tester: InstancePickerTester,
  //     renderer: (props) => <InstancePicker {...props}  />,
  //   },
  // ];


  const getWorkspaceScope = React.useCallback(() => {
    return project?.workspace_id || null;
  }, [project]);

  const customRenderers = React.useMemo(() => [
    ...materialRenderers,
    {
      tester: InstancePickerTester,
      renderer: React.memo((props) => <InstancePicker {...props} workspaceScope={getWorkspaceScope} />),
    },
  ], [materialRenderers, getWorkspaceScope]);

  return (
    <React.Fragment>
      <Drawer open={isDrawer} anchor="right" onClose={() => setDrawer(false)} sx={{ overflow: "none" }}>
        <Box sx={{ minWidth: "34vw", flexGrow: 1, display: "flex", flexDirection: "column", padding: "20px" }}>
          <DataGrid
            rows={components.map((component) => component)}
            getRowHeight={() => "auto"}
            columns={[
              {
                field: "name",
                headerName: project?.name ? `Components (${project.name})` : "Components",
                flex: 1,
                renderCell: (params) => (
                  <div style={{ padding: "15px 0", lineHeight: "normal" }}>
                    {params.row.name}
                    <br />
                    <small>{params.row.description}</small>
                  </div>
                ),
              },
              {
                field: "actions",
                type: "actions",
                getActions: (params) => [
                  <Button onClick={() => addInstance(params.row)}>Add</Button>,
                ],
              },
            ]}
            initialState={{ pagination: { paginationModel: { pservicesSize: 50 } } }}
            disableRowSelectionOnClick
            disableColumnResize
            style={{ border: "none", flexGrow: 1 }}
          />
        </Box>
      </Drawer>

      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>{title}
        </DialogTitle>
        <DialogContent>
          <div>
            <small>You can use <code>$&#123;my_secret_name&#125;</code> in text fields to include secrets in submitted data.</small>
          </div>
          {schema && project && (
            <Box 
            className="jsonforms" style={{width:'500px'}}>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={formData}
              renderers={customRenderers}
              cells={materialCells}
              onChange={setFormResult}
              validationMode={hasTriedToSave ? "ValidateAndShow" : "ValidateAndHide"}
            /></Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
