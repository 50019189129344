class EventEmitter {
    constructor() {
      this.events = {};
    }
  
    on(event, listener) {
      if (!this.events[event]) {
        this.events[event] = [];
      }
      this.events[event].push(listener);
  
      // Retourner une fonction qui permet de désabonner
      const off = () => {
        this.off(event, listener);
      };
      return off;
    }
  
    off(event, listenerToRemove) {
      if (!this.events[event]) return;
  
      this.events[event] = this.events[event].filter(listener => listener !== listenerToRemove);
    }
  
    dispatch(event, data) {
      if (!this.events[event]) return;
  
      this.events[event].forEach(listener => listener(data));
    }
  }

export default EventEmitter;