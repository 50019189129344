import * as React from "react";
import {
  Drawer,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { JsonForms } from "@jsonforms/react";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import {
  Delete as DeleteIcon,
  Security as SecurityIcon,
  PlayArrow as PlayArrowIcon,
  SettingsSuggest as SettingsSuggestIcon,
  FormatListBulleted as FormatListBulletedIcon,
} from "@mui/icons-material";
import OutputIcon from "@mui/icons-material/Output";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";

import AppContext from "../../utils/context";
import { useDialogs } from "@toolpad/core/useDialogs";
import { json } from "react-router-dom";
import {
  InstancePickerTester,
  InstancePicker,
} from "../instances/instancePicker";

export default function ComponentsDrawer(props) {
  const dialogs = useDialogs();
  const { events, database, router } = React.useContext(AppContext);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [services, setServices] = React.useState([]);
  const [configurations, setConfigurations] = React.useState([]);
  const [instance, setInstance] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [formErrors, setFormErrors] = React.useState(null);
  const [hasTriedToSave, setHasTriedToSave] = React.useState(false);
  const [schema, setSchema] = React.useState({});
  const [uischema, setUischema] = React.useState({});
  const [configuration, setConfiguration] = React.useState(null);
  const [workspaceId, setWorkspaceId] = React.useState(null);
  const [dialogDetailsOpen, setDialogDetailsOpen] = React.useState(false);
  const [configurationDetails, setConfigurationDetails] = React.useState({});

  React.useEffect(() => {
    const offList = events.on("list-services", (e) => loadServices(e.instance));
    return () => {
      offList();
    };
  }, []);

  const loadServices = async (instanceData) => {
    setInstance(instanceData);
    const { data: servicesData } = await database
      .from("components_services")
      .select("services(id, name, description)")
      .eq("component_id", instanceData.components.id);

    const { data: configurationsData } = await database
      .from("configurations")
      .select("service_id, metadata, last_http_id, last_http_status")
      .eq("instance_id", instanceData.id);

    setServices(servicesData || []);
    setConfigurations(configurationsData || []);
    setDrawerOpen(true);

    setWorkspaceId(instanceData?.projects?.workspace_id);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSchema({});
    setUischema({});
    setFormErrors(null);
    setFormData({});
    setConfiguration(null);
    setHasTriedToSave(false);
  };

  const updateServiceConfiguration = (serviceId) =>
    configurations.some(({ service_id }) => service_id === serviceId);

  const isHttpError = (serviceId) => 
    configurations.some(({ service_id, last_http_status }) => service_id === serviceId && (last_http_status >= 400 || last_http_status < 200));

  const handleServiceAction = async (action, service) => {
    switch (action) {
      case "run":
        await database.rpc("run_service", {
          qinstance_id: instance.id,
          qservice_id: service.id,
          qaction: "run",
        });
        setTimeout(() => loadServices(instance), 1000);
        break;
      case "edit":
        const { data: configData, error: configError } = await database
          .from("configurations")
          .select(
            "metadata, services(id, name, jsonschema, jsonschema_form), service_id, instance_id, last_http_status"
          )
          .eq("service_id", service.id)
          .eq("instance_id", instance.id)
          .single();

        setConfiguration(configData);

        setSchema(configData?.services?.jsonschema || {});
        setUischema(configData?.services?.jsonschema_form || {});
        setFormData(configData?.metadata || {});
        setDialogOpen(true);
        break;
      case "remove":
        if (
          await dialogs.confirm(
            "Remove configuration? All settings data will be removed."
          )
        ) {
          await database.rpc("run_service", {
            qinstance_id: instance.id,
            qservice_id: service.id,
            qaction: "remove",
          });
          await database
            .from("configurations")
            .delete()
            .eq("instance_id", instance.id)
            .eq("service_id", service.id);
          loadServices(instance);
        }
        break;
      case "enable":
        const { data: serviceData, error: serviceError } = await database
          .from("services")
          .select("id, name, jsonschema, jsonschema_form")
          .eq("id", service.id)
          .single();

        setConfiguration({
          metadata: null,
          instance_id: instance.id,
          service_id: service.id,
          services: serviceData,
        });

        setSchema(serviceData?.jsonschema || {});
        setUischema(serviceData?.jsonschema_form || {});
        setFormData({});
        setDialogOpen(true);

        break;
      case "details":
        const { data: detailsData, error: detailsError } = await database
          .from("configurations")
          .select(
            "last_http_status, last_http_headers, last_http_response, last_http_error, last_http_time"
          )
          .eq("instance_id", instance.id)
          .eq("service_id", service.id)
          .single();

        loadServices(instance);

        setConfigurationDetails(detailsData);
        setDialogDetailsOpen(true);

        break;
      default:
        break;
    }
  };

  const save = async () => {
    setHasTriedToSave(true); // L'utilisateur tente de sauvegarder

    if (formErrors && formErrors.length) {
      events.dispatch("alert-message", {
        data: { message: "Please correct the errors", severity: "error" },
      });
      return;
    }

    try {
      if (configuration?.metadata == null) {
        const { data, error } = await database
          .from("configurations")
          .insert({
            instance_id: configuration.instance_id,
            service_id: configuration.service_id,
            metadata: formData,
          })
          .select()
          .single();
        await database.rpc("run_service", {
          qinstance_id: configuration.instance_id,
          qservice_id: configuration.service_id,
          qaction: "create",
        });
        setTimeout(() => loadServices(instance), 1000);
        if (error) throw error;
      } else {
        const { data, error } = await database
          .from("configurations")
          .update({
            metadata: formData,
          })
          .eq("instance_id", configuration.instance_id)
          .eq("service_id", configuration.service_id)
          .select();
        await database.rpc("run_service", {
          qinstance_id: configuration.instance_id,
          qservice_id: configuration.service_id,
          qaction: "update",
        });
        setTimeout(() => loadServices(instance), 1000);

        if (error) throw error;
      }
    } catch (error) {
      events.dispatch("alert-message", {
        data: {
          message: `Error saving configuration: ${error.message}`,
          severity: "error",
        },
      });
    }
    closeDialog();
  };

  const setFormResult = ({ data, errors }) => {
    setFormData(data);
    setFormErrors(errors);
  };

  const renderServiceActions = (params) =>
    updateServiceConfiguration(params.row.id)
      ? [
          <GridActionsCellItem
            icon={<PlayArrowIcon />}
            label="Run"
            onClick={() => handleServiceAction("run", params.row)}
          />,
          <GridActionsCellItem
            icon={<SettingsSuggestIcon />}
            label="Edit"
            onClick={() => handleServiceAction("edit", params.row)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            onClick={() => handleServiceAction("remove", params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<OutputIcon  color={(isHttpError(params.row.id)) ? "error" : "success"} />}
            label={"Details HTTP"}
            onClick={() => handleServiceAction("details", params.row)}
          />,
          <GridActionsCellItem
            icon={<FormatListBulletedIcon />}
            label="Logs"
            onClick={() => {
              router.navigate(
                `/logs?instance_id=${instance.id}&service_id=${params.row.id}`
              );
              setDrawerOpen(false);
              setServices([]);
              setConfigurations([]);
              setInstance(null);
              return;
            }}
            showInMenu
          />,
        ]
      : [
          <Button onClick={() => handleServiceAction("enable", params.row)}>
            Enable
          </Button>,
        ];

  const customRenderers = React.useMemo(
    () => [
      ...materialRenderers,
      {
        tester: InstancePickerTester,
        renderer: React.memo((props) => (
          <InstancePicker {...props} workspaceScope={() => workspaceId} />
        )),
      },
    ],
    [materialRenderers, workspaceId]
  );

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        sx={{ overflow: "none" }}
      >
        <Box
          sx={{
            minWidth: "34vw",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: 0,
          }}
        >
          <DataGrid
            rows={services.map((s) => s.services)}
            getRowHeight={() => "auto"}
            columns={[
              {
                field: "name",
                headerName: "Services",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div style={{ padding: "15px 0", lineHeight: "normal" }}>
                      {params.row.name}
                      <br />
                      <small>{params.row.description}</small>
                    </div>
                  );
                },
              },
              {
                field: "actions",
                type: "actions",
                width: 160,
                getActions: renderServiceActions,
                align: "right",
              },
            ]}
            initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
            disableRowSelectionOnClick
            disableColumnResize
            style={{ border: "none", flexGrow: 1 }}
          />
        </Box>
      </Drawer>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        {configuration?.services?.name && (
          <DialogTitle>{configuration.services.name}</DialogTitle>
        )}
        <DialogContent>
          <Box sx={{ width: "500px", pt: 2 }}>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={formData}
              renderers={customRenderers}
              cells={materialCells}
              onChange={setFormResult}
              validationMode={
                hasTriedToSave ? "ValidateAndShow" : "ValidateAndHide"
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogDetailsOpen}
        onClose={() => {
          setDialogDetailsOpen(false);
          setConfigurationDetails({});
        }}
      >
        {/* <DialogTitle>{configuration.services.name}</DialogTitle> */}
        <DialogContent>
          <Box sx={{ width: "100%", pt: 2 }}>
            <List sx={{ width: "100%" }}>
              {/* "last_http_status, last_http_headers, last_http_response, last_http_error, last_http_time" */}
              <ListItem>
                <ListItemText>
                  <Typography>Last HTTP Status</Typography>
                  <pre>
                    <code>{configurationDetails.last_http_status}</code>
                  </pre>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Last HTTP Headers</Typography>
                  <pre style={{ overflow: "auto" }}>
                    <code>
                      {JSON.stringify(
                        configurationDetails.last_http_headers,
                        null,
                        " "
                      )}
                    </code>
                  </pre>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Last HTTP Response</Typography>
                  <pre style={{ overflow: "auto" }}>
                    <code>{configurationDetails.last_http_response}</code>
                  </pre>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Last HTTP Error</Typography>
                  <pre style={{ overflow: "auto" }}>
                    <code>{configurationDetails.last_http_error}</code>
                  </pre>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Last HTTP Time</Typography>
                  <pre>
                    <code>{configurationDetails.last_http_time}</code>
                  </pre>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogDetailsOpen(false);
              setConfigurationDetails({});
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
