export const schema = {
    "type": "object",
    "properties": {
      "name": {
        "type": "string",
        "minLength": 3,
        "description": "Please enter a project name"
      },
      "description": {
        "type": "string",
        "description": "Please enter a description for the project"
      }
    },
    "required": [
      "name"
    ]
  }

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/name",
    },
    {
      type: "Control",
      scope: "#/properties/description",
      options: {
        multi: true,
        rows: 2,
      }
    }
  ],
}