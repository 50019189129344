import React, { useEffect } from "react";
import "./App.scss";
import { createClient } from "@supabase/supabase-js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  useColorScheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./layout/index";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppContext from "./utils/context";
import DashboardView from "./pages/dashboard";
import ProjectView from "./pages/projects";
import AccountView from "./pages/me";
import EventEmitter from "./utils/eventEmitter";
import { useDialogs } from "@toolpad/core/useDialogs";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import LogView from "./pages/logs";

const darkTheme = createTheme({
  colorSchemes: {
    dark: true,
  },
});

const supabase = createClient(
  window.api.base_url,
  window.api.public_key,
  window.api.config
);

function App() {
  const { mode, setMode } = useColorScheme();
  const [session, setSession] = React.useState(null);
  const [events, setEvents] = React.useState(null);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <DashboardView />,
        },
        {
          path: "/project/:projectId",
          element: <ProjectView />,
        },
        {
          path: "/project/:projectId/instance/:instanceId",
          element: <ProjectView />,
        },
        {
          path: "/logs",
          element: <LogView />,
        },
      ]
    },
  ]);

  useEffect(() => {
    setEvents(new EventEmitter());
    setMode("system");
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const AuthView = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={[]}
            view="sign_in"
          />
        </CardContent>
      </Card>
    </Box>
  );

  const AppView = (
    <>
      {supabase && router && (
        <AppContext.Provider
          value={{
            database: supabase,
            router: router,
            events: events,
          }}
        >
            <RouterProvider router={router} />
        </AppContext.Provider>
      )}
    </>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />
      {(session ? AppView : AuthView)}
    </ThemeProvider>
  );
}

export default App;
