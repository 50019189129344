import * as React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDialogs } from "@toolpad/core/useDialogs";
import SecurityIcon from "@mui/icons-material/Security";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AppContext from "../../utils/context";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

export default function View() {
  const dialogs = useDialogs();
  const appContext = React.useContext(AppContext);
  const [project, setProject] = React.useState(null);
  const [anchorElProject, setAnchorElProject] = React.useState(null);
  const { events } = appContext;
  const [filters, setFilters] = React.useState({ items: [] });

  let { projectId, instanceId } = useParams();

  React.useEffect(() => {
    const off = events.on("refresh-instances", () => loadProject());

    return () => {
      off();
    };
  }, []);

  React.useEffect(() => {
    loadProject();
  }, [projectId, instanceId]);

  React.useEffect(() => {
    if (instanceId) {
      setFilters({
        items: [
          {
            field: "id",
            operator: "equals",
            value: instanceId,
          },
        ],
      });
    } else {
      setFilters({ items: [] });
    }
  }, [instanceId]);

  const settings = [
    {
      name: "Edit project",
      onclick: () => {
        events.dispatch("edit-project", {
          data: project,
          callback: () => {
            events.dispatch("refresh-projects");
            loadProject();
          },
        });
        handleCloseProjectMenu();
      },
    },
    {
      name: "Remove project",
      onclick: async () => {
        const confirmed = await dialogs.confirm(
          `Remove project "${project.name}" ?`,
          {
            okText: "Yes",
            cancelText: "No",
          }
        );
        if (confirmed) {
          const { data, error } = await appContext.database
            .from("projects")
            .delete()
            .eq("id", projectId)
            .select();

          if (!error) {
            appContext.router.navigate(`/`);
            events.dispatch("refresh-projects");
            events.dispatch("alert-message", {
              data: {
                message: "Project deleted successfully",
                severity: "success",
              },
            });
          } else {
            events.dispatch("alert-message", {
              data: {
                message: "Error deleting project",
                severity: "error",
              },
            });
          }
        }
        handleCloseProjectMenu();
      },
    },
    {
      name: "Logs",
      onclick: () => {
        appContext.router.navigate(`/logs?project_id=${projectId}`);
        handleCloseProjectMenu();
      },
    },
  ];

  const loadProject = async () => {
    // const { data, error } = await appContext.database.from("instances").select('*,projects(name)').eq('project_id', projectId);
    const { data, error } = await appContext.database
      .from("projects")
      .select(
        `
        id,
        name,
        workspace_id,
        description,
        project_template_id,
        instances(
          id,
          name,
          services(count),
          components(
            id,
            name,
            services(count)
          )
        )
      `
      )
      .eq("id", projectId);
    setProject(data[0]);
  };

  const editServicesInstance = async (instance) => {
    events.dispatch("list-services", {
      instance: {
        ...instance,
        projects: { workspace_id: project.workspace_id },
      },
      callback: () => {
        // loadProject();
      },
    });
  };

  const editSecretsInstance = async (instance) => {
    events.dispatch("list-secrets", {
      data: instance,
      callback: () => {
        // loadProject();
      },
    });
  };

  const deleteInstance = async (instance) => {
    const confirmed = await dialogs.confirm(
      `Remove instance "${instance.name}" ?`,
      {
        okText: "Yes",
        cancelText: "No",
      }
    );
    if (confirmed) {
      const { data, error } = await appContext.database
        .from("instances")
        .delete()
        .eq("id", instance.id)
        .select();

      if (!error) {
        events.dispatch("alert-message", {
          data: {
            message: "Instance deleted successfully",
            severity: "success",
          },
        });
        loadProject();
      } else {
        events.dispatch("alert-message", {
          data: {
            message: "Error deleting instance",
            severity: "error",
          },
        });
      }
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "components",
      headerName: "Type",
      width: 150,
      valueGetter: (params) => params?.name,
    },
    {
      field: "services",
      headerName: "Services",
      width: 110,
      disableColumnMenu: true, 
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.services?.[0]?.count
              ? params.row.services[0].count
              : "-"}{" "}
            /{" "}
            {params?.row?.components?.services?.[0]?.count
              ? params.row.components.services[0].count
              : "-"}
          </div>
        );
      },
    },
    { field: "id", headerName: "ID", width: 300 },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ShareIcon />}
          label="Services"
          onClick={() => editServicesInstance(params.row)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => {
            events.dispatch("edit-instance", {
              instance: params.row,
              workspaceId: project.workspace_id,
              project: project,
              callback: () => {
                loadProject();
              },
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<SecurityIcon />}
          label="Secrets"
          onClick={() => editSecretsInstance(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Remove"
          onClick={() => deleteInstance(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<FormatListBulletedIcon />}
          label="Logs"
          onClick={() => {
            appContext.router.navigate(`/logs?instance_id=${params.row.id}`);
            return;
          }}
          showInMenu
        />,
      ],
    },
  ];

  const handleOpenProjectMenu = (event) => {
    setAnchorElProject(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setAnchorElProject(null);
  };

  return (
    <div>
      <div className="wrapper" style={{ display: "flex", padding: "20px" }}>
        <Paper
          style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ padding: "10px", display: "flex", flexDirection: "row" }}>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              {project && (
                <h3 style={{ padding: 0, margin: 0 }}>{project.name}</h3>
              )}
            </Box>
            <Box>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  events.dispatch("create-instance", {
                    project: project,
                    callback: (result) => {
                      loadProject();
                    },
                  });
                }}
              >
                Add New
              </Button>
              <IconButton onClick={handleOpenProjectMenu} aria-label="settings">
                <SettingsSuggestIcon />
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElProject}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElProject)}
                onClose={handleCloseProjectMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={(e) => setting.onclick()}>
                    <Typography sx={{ textAlign: "center" }}>
                      {setting.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          {project && (
            <DataGrid
              rows={project.instances}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pservicesSize: 50,
                  },
                },
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }],
                },
              }}
              disableRowSelectionOnClick={true}
              disableColumnResize={true}
              filterModel={filters}
              onFilterModelChange={(model) => setFilters(model)}
              style={{ border: "none", flexGrow: 1 }}
            />
          )}
        </Paper>
      </div>
    </div>
  );
}
