import * as React from "react";
import { createBrowserRouter, RouterProvider, Outlet, useLocation  } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MainMenu from "./menu";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AppContext from "../utils/context";
import { DialogsProvider, useDialogs } from "@toolpad/core/useDialogs";

import ProjectDialog from "../dialogs/project";
import InstancesDialog from "../dialogs/instances";
import ConfigurationsDialog from "../dialogs/configurations";
import SecretsDialog from "../dialogs/secrets";
import SearchDialog from "../dialogs/search";

export default function Layout(props) {
  const appContext = React.useContext(AppContext);
  const { events } = appContext;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState({});
  const location = useLocation();

  React.useEffect(() => {
    const offMessage = events.on("alert-message", alertMessage);
    return () => {
      offMessage();
    };
  }, []);

  const alertMessage = (e) => {
    setMessage(e.data);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setMessage({});
  };

  return (
    <DialogsProvider>
      <Box className="App">
        <Box
          sx={{
            width: "100%",
            maxWidth: 300,
            margin: "20px 0 20px 20px",
            display: "flex",
          }}
        >
          <Paper sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <MainMenu />
          </Paper>
          <ProjectDialog />
          <ConfigurationsDialog />
          <InstancesDialog />
          <SecretsDialog />
          <SearchDialog />
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={message.severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message.message}
            </Alert>
          </Snackbar>
        </Box>
        <Outlet />
      </Box>
    </DialogsProvider>
  );
}
