import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import AppContext from "../../utils/context";
import Markdown from 'react-markdown'

export default function View() {
  const appContext = useContext(AppContext);
  const [dashboard, setDashboard] = useState("");

  const loadDashboard = async () => {
    const { data, error } = await appContext.database.auth.getSession();

    const { data: dashboardData, error: dashboardError } =
      await appContext.database.rpc("get_dashboard", {
        quser_id: data.session.user.id
      });
    setDashboard(dashboardData);
  };

  useEffect(() => {
    loadDashboard();
  }, []);

  return (
    <div>
      <div className="wrapper" style={{ display: "flex", padding: "20px" }}>
        <Paper
          style={{ flexGrow: 1, display: "flex", flexDirection: "column", padding: "20px" }}
        >
          {dashboard && <Markdown>{dashboard}</Markdown>}
        </Paper>
      </div>
    </div>
  );
}
