import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import AppContext from "../../utils/context";
import { schema, uischema } from "./schema";
import { useDialogs } from "@toolpad/core/useDialogs";

export default function ComponentsDrawer(props) {
  const dialogs = useDialogs();
  const appContext = React.useContext(AppContext);
  const { events, database } = appContext;

  const [isDrawer, setDrawer] = React.useState(false);
  const [secrets, setSecrets] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [formErrors, setFormErrors] = React.useState(null);
  const [hasTriedToSave, setHasTriedToSave] = React.useState(false);
  const [instance, setInstance] = React.useState(null);

  React.useEffect(() => {
    const offList = events.on("list-secrets", (data) => handleList(data));
    return () => {
      offList();
    };
  }, []);

  const handleList = (e) => {
    setInstance(e.data);
    setDrawer(true);
  };

  React.useEffect(() => {
    if (instance) {
      loadSecrets();
    }
  }, [instance]);

  const loadSecrets = async () => {
    try {
      const { data: secretsData, error: secretsError } = await database.rpc(
        "get_secrets_by_instance",
        { qinstance_id: instance.id }
      );
      if (secretsError) throw secretsError;
      setSecrets(
        secretsData.map((s) => ({
          ...s,
          name: s.name.replace(/^[0-9a-fA-F-]{36}_/, ""),
        }))
      );
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error loading secrets: ${error.message}`, severity: "error" },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({});
    setFormErrors(null);
    setHasTriedToSave(false);
  };

  const editSecret = (secret) => {
    setOpen(true);
    setFormData(secret);
  };

  const newSecret = () => {
    setOpen(true);
    setFormData({
      id: null,
      quniqname: "",
      secret: "",
      description: "",
    });
  };

  const deleteSecret = async (secret) => {
    const confirmed = await dialogs.confirm(
      `Remove secret "${secret.name}" ?`,
      { okText: "Yes", cancelText: "No" }
    );
    if (confirmed) {
      try {
        const { error } = await database.rpc("delete_secret", {
          qsecret_id: secret.id,
        });
        if (error) throw error;
        loadSecrets(); // Refresh the list after deletion
      } catch (error) {
        events.dispatch("alert-message", {
          data: { message: `Error deleting secret: ${error.message}`, severity: "error" },
        });
      }
    }
  };

  const save = async () => {
    setHasTriedToSave(true);

    if (formErrors && formErrors.length) {
      events.dispatch("alert-message", {
        data: { message: "Please correct the errors", severity: "error" },
      });
      return;
    }

    try {
      if (formData.id == null) {
        const { error } = await database.rpc("create_secret", {
          quniqname: `${instance.id}_${formData.name}`,
          qsecret: formData.secret,
          qdescription: formData?.description?.length ? formData.description : "",
        });
        if (error) throw error;
      } else {
        const d = {
          qsecret_id: formData.id,
          quniqname: `${instance.id}_${formData.name}`,
          qsecret: formData.secret?.length ? formData.secret : null,
          qdescription: formData?.description?.length ? formData.description : "",
        };
        const { error } = await database.rpc("update_secret", d);
        if (error) throw error;
      }
      handleClose();
      loadSecrets(); // Refresh the list after saving
    } catch (error) {
      events.dispatch("alert-message", {
        data: { message: `Error saving secret: ${error.message}`, severity: "error" },
      });
    }
  };

  const setFormResult = ({ data, errors }) => {
    setFormData(data);
    setFormErrors(errors);
  };

  return (
    <React.Fragment>
      <Drawer
        open={isDrawer}
        anchor="right"
        onClose={() => setDrawer(false)}
        sx={{ overflow: "none" }}
      >
        <Box sx={{ minWidth: "34vw", flexGrow: 1, display: "flex", flexDirection: "column", padding: "20px" }}>
          <DataGrid
            rows={secrets}
            getRowHeight={() => "auto"}
            columns={[
              {
                field: "name",
                headerName: "Secret",
                flex: 1,
                renderCell: (params) => (
                  <div style={{ padding: "15px 0", lineHeight: "normal" }}>
                    {params.row.name}
                    <br />
                    <small>{params.row.description}</small>
                  </div>
                ),
              },
              {
                field: "actions",
                type: "actions",
                getActions: (params) => [
                  <GridActionsCellItem
                    icon={<SettingsSuggestIcon />}
                    label="Edit"
                    onClick={() => editSecret(params.row)}
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Remove"
                    onClick={() => deleteSecret(params.row)}
                    showInMenu
                  />,
                ],
                renderHeader: () => (
                  <Button size="small" onClick={newSecret}>
                    New secret
                  </Button>
                ),
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 50 },
              },
            }}
            disableRowSelectionOnClick
            disableColumnResize
            style={{ border: "none", flexGrow: 1 }}
          />
        </Box>
      </Drawer>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Secret</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "500px", paddingTop: "20px" }} className="jsonforms">
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={formData}
              renderers={materialRenderers}
              cells={materialCells}
              onChange={setFormResult}
              validationMode={hasTriedToSave ? "ValidateAndShow" : "ValidateAndHide"}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
