export const schema = {
  "type": "object",
  "properties": {
    "name": {
      "type": "string",
      "minLength": 3,
      "description": "Enter the name of the secret"
    },
    "secret": {
      "type": "string",
      "minLength": 3,
      "description": "Enter the secret"
    },
    "description": {
      "type": "string",
      "description": "Set the description"
    }
  },
  "required": [
    "name"
  ]
}

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/name",
    },
    {
      type: "Control",
      scope: "#/properties/description",
      options: {
        multi: true,
        rows: 2,
      }
    },
    {
      type: "Control",
      scope: "#/properties/secret",
    },
  ],
}