import * as React from "react";
import { createBrowserRouter, RouterProvider,useNavigate   } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import StarBorder from "@mui/icons-material/StarBorder";
import CommentIcon from "@mui/icons-material/Comment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import AppContext from "../utils/context";

export default function Layout(props) {
  const appContext = React.useContext(AppContext);
  const { events } = appContext;
  const [projects, setProjects] = React.useState([]);
  const [workspaces, setWorkspaces] = React.useState([]);
  const [workspacesOpen, setWorkspacesOpen] = React.useState([]);
  const [anchorElWorkspace, setAnchorElWorkspace] = React.useState(null);
  const [workspaceMenu, setWorkspaceMenu] = React.useState(null);
  let navigate = useNavigate();

  const search = () => {
    events.dispatch('search', {
      callbacks:{
        workspace: (workspace) => {
          setWorkspacesOpen([workspace.id])
          return;
        },
        project: (project) => {
          navigate(`/project/${project.id}`)
          return;
        },
        instance: (instance) => {
          navigate(`/project/${instance.projects.id}/instance/${instance.id}`)
          return;
        },
      }
    })
  };

  React.useEffect(() => {
    const off = events.on('refresh-projects', (data) => loadProjects());
    return () => {
      off();
    };
  }, []);

  const menuOptions = [
    {
      name: "Nouveau projet",
      onClick: (e) => {
        events.dispatch("create-project", {
          data: workspaceMenu,
          callback: (result) => {
            loadProjects();
            appContext.router.navigate(`/project/${result.id}`);
          },
        });
        handleCloseWorkspaceMenu(); 
      },
    },
    {
      name: "Logs",
      onClick: () => {
        appContext.router.navigate(`/logs?workspace_id=${workspaceMenu.id}`);
        handleCloseWorkspaceMenu();
      },
    },
  ];

  const handleOpenWorkspaceMenu = (event, workspace) => {
    setAnchorElWorkspace(event.currentTarget);
    setWorkspaceMenu(workspace);
  };

  const handleCloseWorkspaceMenu = () => {
    setAnchorElWorkspace(null);
    setWorkspaceMenu(null);
  };

  const loadProjects = async () => {
    const { data, error } = await appContext.database.from("projects").select().order("name") ;
    setProjects([...data]);
  };
  const loadWorkspaces = async () => {
    const { data, error } = await appContext.database
      .from("workspaces")
      .select();
    setWorkspaces([...data]);
  };

  React.useEffect(() => {
    loadWorkspaces() && loadProjects();
  }, []);

  const handleClick = (item) => {
    if (workspacesOpen.includes(item.id)) {
      setWorkspacesOpen(workspacesOpen.filter((i) => i !== item.id));
    } else {
      setWorkspacesOpen([item.id]);
    }
  };

  const openProject = (project) => {
    appContext.router.navigate(`/project/${project.id}`);
  };

  return (
    <>
      <Box>
        <List
          sx={{
            width: "100%",
            flexGrow: 1,
            paddingBottom: 0,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
          // }
        >
          <ListItem style={{ borderBottom: "1px solid #555" }}>
            <div primary={`Plexia`} style={{ fontWeight: "800" }}>
              {window?.api?.app_name ? `${window.api.app_name}` : ''}
            </div>
          </ListItem>
          <ListItem
            secondaryAction={
              <IconButton edge="end" aria-label="Search"  onClick={(e) => {
                search()
              }}>
                <SearchIcon />
              </IconButton>
            }
            disablePadding
            style={{ borderBottom: "1px solid #555" }}
          >
            <ListItemButton
              role={undefined}
              onClick={() => appContext.router.navigate(`/`)}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={`Dashboard`} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box style={{ overflow: "auto", flexGrow: 1, height: 0 }}>
        <List
          sx={{ width: "100%", flexGrow: 1, paddingTop: 0 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
          // }
        >
          {/* <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <CommentIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined}>
                    <ListItemIcon>
                      <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Line item`} />
                  </ListItemButton>
                </ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Drafts" />
                </ListItemButton>
                <ListItemButton onClick={handleClick}>
                <ListItemText primary="Inbox" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton> */}
          {workspaces.map((workspace, i) => (
            <div key={i}>
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={(e) => handleOpenWorkspaceMenu(e, workspace)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton onClick={() => handleClick(workspace)}>
                  <ListItemText primary={workspace.name} />
                  {workspacesOpen.includes(workspace.id) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse
                in={workspacesOpen.includes(workspace.id)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {projects.map((project, k) => {
                    if (project.workspace_id == workspace.id) {
                      return (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={k}
                          onClick={() => openProject(project)}
                        >
                          <ListItemText primary={project.name} />
                        </ListItemButton>
                      );
                    }
                  })}
                </List>
              </Collapse>
            </div>
          ))}

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElWorkspace}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElWorkspace)}
            onClose={handleCloseWorkspaceMenu}
          >
            {menuOptions.map((option, i) => (
              <MenuItem key={i} onClick={() => option.onClick()}>
                <Typography sx={{ textAlign: "center" }}>
                  {option.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </List>
      </Box>
      <Box>
        <List
          sx={{ width: "100%", flexGrow: 1, paddingBottom: 0 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
          // }
        >
          <ListItem
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                appContext.router.navigate(`/logs`)
              }}
              role={undefined}
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary={`Logs`} />
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <IconButton edge="end" aria-label="comments" onClick={(e) => {
                appContext.database.auth.signOut()
              }}>
                <ExitToAppIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                if(window?.api?.account_url){
                  window.open(window.api.account_url, '_blank');
                }
              }}
              role={undefined}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={`Account`} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );
}
