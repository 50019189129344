import {
  rankWith,
  uiTypeIs,
  schemaTypeIs,
  isStringControl,
  Resolve,
} from "@jsonforms/core";
import React, { useState, useEffect } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { TextField, IconButton, Box } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import AppContext from "../../utils/context"; // Importer AppContext pour Supabase
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const InstancePickerControl = ({
  data,
  handleChange,
  path,
  errors,
  uischema,
  schema,
  loadOptions,
  workspaceScope
}) => {
  const appContext = React.useContext(AppContext); // Utiliser le contexte de l'application
  const { events, database } = appContext; // Extraire les événements et la base de données de l'application
  const [instance, setInstance] = useState(null); // État pour stocker les données de l'instance
  const [isInstanceMissing, setIsInstanceMissing] = useState(false); // Indicateur si l'instance est manquante

  // Fonction pour charger les données d'une instance spécifique
  const loadInstanceData = async (id) => {
    if (!id) return;

    try {
      const { data: instanceData, error } = await database
        .from("instances")
        .select('id, name, projects(id, name, workspaces(id, name))')
        .eq("id", id)
        .single(); // Utiliser .single() pour récupérer une seule instance

      if (error) {
        // Si l'instance n'existe plus, on considère que l'instance est manquante
        setIsInstanceMissing(true);
        setInstance(null);
      } else {
        setInstance(instanceData); // Mettre à jour l'instance si les données sont chargées correctement
        setIsInstanceMissing(false); // L'instance est bien trouvée
      }
    } catch (error) {
      // Si une erreur survient (par ex. l'instance est supprimée), on n'affiche pas de notification d'erreur mais on indique que l'instance est manquante
      console.error("Erreur lors du chargement de l'instance :", error);
      setIsInstanceMissing(true); // L'instance est manquante, on l'indique
      setInstance(null); // Réinitialiser l'instance car elle n'existe plus
    }
  };

  // Effectue une mise à jour lorsque les données du formulaire changent
  useEffect(() => {
    loadInstanceData(data);
  }, [data]); // Recharger lorsque `data` change

  // Fonction déclenchée lorsque l'utilisateur sélectionne une instance
  const pickItem = () => {
    events.dispatch("search", {
      workspaceScope: workspaceScope ? workspaceScope() : null,
      componentScope: (schema?.picker && Array.isArray(schema.picker) && schema.picker.length > 0) ? schema.picker : null,
      callbacks: {
        workspace: null,
        project: null,
        instance: (instance) => {
          handleChange(path, instance.id); // Met à jour la valeur avec l'ID de l'instance sélectionnée
        },
      },
    });
  };

  const removeItem = () => {
    handleChange(path, '');
    setInstance(null);
    setIsInstanceMissing(false);
  };

  return (
    <>
      {/* Boîte principale pour l'input et le bouton */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <TextField
          disabled
          variant="outlined"
          placeholder={schema.title}
          value={instance ? instance.name : (isInstanceMissing ? data : '')} // Affiche le nom de l'instance si elle est disponible, sinon affiche l'UUID
          label={schema.title}
          error={!!errors} // Marquer comme erreur si errors contient quelque chose
          helperText={errors} // Afficher le texte d'aide s'il y a une erreur
          style={{ flexGrow: 1 }}
        />
        {/* Bouton pour ouvrir le sélecteur d'instance */}
        <IconButton
          color="primary"
          sx={{ ml: "10px" }}
          aria-label="select instance"
          onClick={pickItem}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          color="primary"
          sx={{ ml: "10px" }}
          aria-label="remove instance"
          onClick={removeItem}
        >
          <ClearIcon />
        </IconButton>
      </Box>

      {/* Informations supplémentaires sur l'instance */}
      {instance && (
        <Box style={{ marginTop: '10px' }}>
          <small>
            Instance : {instance.name} / Projet : {instance.projects.name} / Workspace : {instance.projects.workspaces.name}
          </small>
        </Box>
      )}

      {/* Afficher l'UUID si l'instance est manquante */}
      {isInstanceMissing && (
        <Box style={{ marginTop: '10px' }}>
          <small>Instance manquante, UUID : {data}</small>
        </Box>
      )}
    </>
  );
};

// Tester pour vérifier que le champ a le bon format 'instance-picker'
const InstancePickerTester = rankWith(10, (uischema, schema) => {
  const controlSchema = Resolve.schema(schema, uischema.scope);
  return (
    controlSchema &&
    controlSchema.type === "string" &&
    controlSchema?.picker
  );
});

// Connexion du composant avec les props JsonForms
const InstancePicker = withJsonFormsControlProps(InstancePickerControl);

export { InstancePicker, InstancePickerTester };
